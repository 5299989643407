/*
Custom CSS styles for the whole app.
*/


/* General styles */
html,
body {
    height: 100%;
}
.container{
    padding-top: 15px;
}

.container-full-width {
  margin: 0 auto;
  width: 100%;
}

.with-padding{
    padding-top:12px;
}

#search-bar {
    width: 200px
}

.dropdown {
    margin: 0px 5px;
}

#privacy{
    width: 90%
}

/* Navbar */
.navbar {
    min-height:40px !important
}

.navbar {
    padding:0px !important
}

/* Tree styling */
.node {
    cursor: pointer;
}

.overlay{
    background-color:#EEE;
}

.node circle {
    fill: #fff;
    stroke: steelblue;
    stroke-width: 1.5px;
  }

.node text {
    font-size:10px;
    font-family:sans-serif;
}

.link {
    fill: none;
    stroke: #ccc;
    stroke-width: 1.5px;
}

/* Sticky footer */
/* Wrapper for page content to push down footer */
#wrap {
    min-height: 100%;
    height: auto !important;
    height: 100%;
    /* Negative indent footer by it's height */
    margin: 0 auto -40px;
}

/* Set the fixed height of the footer here */
#push,
#footer {
    height: 40px;
}

/* Lastly, apply responsive CSS fixes as necessary */
@media (max-width: 767px) {
    #footer {
        margin-left: -20px;
        margin-right: -20px;
        padding-left: 20px;
        padding-right: 20px;
    }
}

/* Autocomplete */
.ui-autocomplete {
    max-height: 500px;
    overflow-y: auto;
    /* prevent horizontal scrollbar */
    overflow-x: hidden;
}
/* IE 6 doesn't support max-height
* we use height instead, but this forces the menu to always be this tall
*/
* html .ui-autocomplete {
    height: 500px;
    z-index: 100;
}

.ui-autocomplete .ui-menu-item > a.ui-corner-all{
    background: none;
}

.ui-menu .ui-menu-item a.ui-state-hover,
.ui-menu .ui-menu-item a.ui-state-focus {
    background: none;
    background-color:#B0C4DE;
    border-color: steelblue;
    color:white;

}

/* Modals */
#credits_modal .modal-dialog{
    width: 70%;
}